/* Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root {
  --primary-color: #57bfffcf; 
  --secondary-color: #64f7ff;
  --text-color: #000; 
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.891);
    color: var(--text-color);
    padding: 10px 15px;
    position: fixed;
    top: 0; 
    left: 0;
    right: 0;
    z-index: 1000;
}

.nav-text-main {
    font-family: 'Bebas Neue', sans-serif;
}

.nav-logo {
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer; 
}

.nav-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin: 0 15px;
}

.nav-links li a {
    color: var(--text-color); 
    text-decoration: none;
    transition: color 0.3s;
    font-size: 1.1rem;
    padding: 10px;
}

.nav-links li a:hover {
    color: #f0f0f0;
}

.nav-link {
    font-family: 'Bebas Neue', sans-serif;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
    transition: transform 0.3s ease;
}

.hamburger {
    width: 30px;
    height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line {
    width: 100%;
    height: 4px;
    background-color: var(--text-color); 
    transition: all 0.3s ease;
    border-radius: 2px;
    transform-origin: center;
}

.line-1 {
    transform: rotate(0);
}

.line-2 {
    opacity: 1;
}

.line-3 {
    transform: rotate(0);
}

.menu-toggle.active .line-1 {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.active .line-2 {
    opacity: 0;
}

.menu-toggle.active .line-3 {
    transform: rotate(-45deg) translate(5px, -5px);
}

.menu-toggle:hover .line {
    background: linear-gradient(135deg, #0bc2ff 0%, #287ff9 100%);
}


@media (max-width: 768px) {
    .nav-links {
        position: fixed;
        top: 60px;
        right: -100%;
        flex-direction: column;
        background: linear-gradient(135deg, #ffffff, #cacaca);
        width: 80%;
        max-width: 150px;
        text-align: right;
        padding: 0px 0;
        transition: right 0.5s ease-in-out;
        box-shadow: -2px 0 1px rgba(0, 0, 0, 0.3);
        z-index: 1000;
    }
.nav-link {
    max-height: 20px;
}
    .nav-links.active {
        right: 0;
    }

    .nav-links li {
        margin: 10px 0;
        font-size: 1rem;
        font-weight: bold;
        list-style: none;
        display: flex;
        justify-content: center; 
    }

    .nav-links li a {
        color: rgb(0, 0, 0);
        text-decoration: none;
        transition: color 0.3s ease, background 0.3s ease;
        display: inline-block;
        width: 80%; 
        max-width: 200px;
        padding: 15px 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transform: perspective(1px) translateZ(0);
        transition: transform 0.3s ease;
        text-align: center; 
    }

    .nav-links li a:hover {
        color: #ffffff;
        background: rgba(255, 255, 255, 0.3);
        transform: perspective(1px) translateZ(0) scale(1.1);
    }

    .menu-toggle {
        display: block;
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        z-index: 1001;
        border-radius: 10px;
    }

    .menu-toggle.active .line-1 {
        transform: rotate(45deg) translateY(8px);
    }

    .menu-toggle.active .line-2 {
        opacity: 0;
    }

    .menu-toggle.active .line-3 {
        transform: rotate(-45deg) translateY(-8px);
    }

    .menu-toggle span {
        display: block;
        width: 35px;
        height: 5px;
        margin: 6px auto;
        background-color: var(--text-color); 
        transition: all 0.3s ease;
    }
}

.nav-logo .github-icon {
  margin-left: 15px;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
  transition: color 0.3s;
}

.nav-logo .github-icon:hover {
  color: #333;
}
