@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

:root {
  --primary-color: #57bfffcf;
  --secondary-color: #64b9ff;
  --text-color: #333;
  --background-color: #fff;
}

html {
  scroll-behavior: smooth;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Jost', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: var(--background-color);
  }
  
  .contact-container {
    overflow-y: hidden; 
  }
  

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-color); 
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; 
  background-color: #000; 
  opacity: 0; 
  transition: opacity 1s ease-in-out; 
}

.contact-section.in-view {
  opacity: 1; 
}

.contact-section .icon {
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.contact-section h2 {
  font-size: 2rem;
  color: var(--primary-color); 
  margin-bottom: 10px;
}

.contact-section p, .contact-section a {
  font-size: 1.5rem;
  color: var(--primary-color); 
  margin: 5px 0;
}

.contact-section a {
  text-decoration: none;
  color: var(--secondary-color); 
}

.contact-section a:hover {
  text-decoration: underline;
}

.kaleidoscope-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: var(--primary-color);
  z-index: 1;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  z-index: 1;
}

p {
  font-size: 1.2rem;
  z-index: 1;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--secondary-color);
}

.nav-button {
    background: linear-gradient(135deg, #0bc2ff 0%, #287ff9 100%);
    border: none;
    color: var(--text-color);
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    position: fixed; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    opacity: 0.3; 
  }
  
  .nav-button.up {
    top: 50px;
  }
  
  .nav-button.down {
    bottom: 50px;
  }
  
  .nav-button:hover {
    background-color: var(--primary-color);
    opacity: 1; 
  }

.socialmediaicon {
    font-size: 3rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    z-index: 1;
  }

  .socialmedia {
    font-size: 2.5rem;
    margin-bottom: 10px;
    z-index: 1;
    color: var(--primary-color); 
  }
  

  .socialmedia-animate-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    font-size: 1.5rem;
    color: var(--primary-color);
    margin: 5px 0;
  }
  

  .contact-section.in-view .socialmedia-animate-content {
    opacity: 1;
    transform: translateY(0);
  }
  

  .socialmedia-animate-content a {
    color: var(--secondary-color); 
    text-decoration: none;
    margin-left: 10px; 
  }
  
  .socialmedia-animate-content a:hover {
    text-decoration: underline;
  }
  

  .socialmedia-animate-content i {
    margin-right: 10px; 
    color: var(--secondary-color); 
  }
  
  .contact-button {
    background: linear-gradient(135deg, #0bc2ff 0%, #287ff9 100%);
    border: 1px solid var(--primary-color);
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
  }
  
  .contact-button a {
    color: inherit;
    text-decoration: none;
    margin-left: 10px;
  }
  
  .contact-button i {
    margin-right: 10px;
  }
  

@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 2rem;
  }
}

.contact-section .animate-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .contact-section.in-view .animate-content {
    opacity: 1;
    transform: translateY(0);
  }
  



